.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.layer-list {
  width: 100%;
  border-collapse: collapse;
  margin: 2px 0;
  font-size: 14px;
  text-align: left;
}

.layer-list thead tr {
  background-color: #f2f2f2;
  border-bottom: 5px solid #ddd;
}

.layer-list th, .layer-list td {
  padding: 5px;
}

.layer-list th {
  font-weight: bold;
}

.layer-list tbody tr:nth-of-type(even) {
  background-color: #f9f9f9;
}

.layer-list tbody tr:hover {
  background-color: #f1f1f1;
}

.layer-list tbody td {
  border-bottom: 1px solid #ddd;
}

.layer-list tbody button.copy-clipboard {
  background-color: #00AC69;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 6px 10px;
  cursor: pointer;
}

.layer-list tbody button.copy-clipboard:hover {
  background-color: #1CE783;
}

.layer-list tbody button.copy-clipboard:active {
  background-color: #00AC69;
}

.layer-arn {
  font-family: "Courier New", Courier, monospace;
  font-weight: 600;
  background-color: #e8e8e8;
  border-radius: 4px;
  padding: 4px;
  display: inline-block;
  color: #000000;
}

.container {
  width: calc(100% - 40px); 
  margin: 0 auto;
  padding: 2px;
}

.container h1 {
  font-size: 20px;
  color: #000000;
  margin-bottom: 2px;
}

.container p {
  font-size: 14px;
  margin-bottom: 2px;
  line-height: 1;
  color: #000000;
}

.container a {
  color: #1a7efb;
  text-decoration: none;
}

.container a:hover {
  text-decoration: underline;
}

.select-container {
  margin: 10px 0;
}

.tabs {
  margin-top: 10px;
}

.tab-list {
  display: flex;
  border-bottom: 2px solid #ccc;
  margin-bottom: 10px;
}

.tab {
  margin-right: 20px;
  cursor: pointer;
  padding-bottom: 10px;
}

.tab.active {
  font-weight: bold;
  border-bottom: 2px solid #1a7efb;
}

.tab-panel {
  display: none;
}

.tab-panel.active {
  display: block;
}
